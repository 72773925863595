import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import firebase from 'firebase';
import './App.scss';

// auth
import Auth from './components/Auth';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Client = Loadable({
  loader: () => import('./views/Pages/Client'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

// import { renderRoutes } from 'react-router-config';

window.uid = '';

class App extends Component {

  constructor(props) {
    super(props);
    var config = {
      apiKey: "AIzaSyAWaCnI3Yc4Cf5mezrWmVtkIBxO9kGLrvo",
      authDomain: "mgy-nfc.firebaseapp.com",
      databaseURL: "https://mgy-nfc.firebaseio.com",
      storageBucket: "mgy-nfc.appspot.com",
      projectId: "mgy-nfc",
    };
    firebase.initializeApp(config);
  }

  state = {
    uid: '-1',
  }

  componentWillMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const db = firebase.firestore();
        window.uid = user.uid;
        this.setState({ uid: user.uid });
      } else {
        window.uid = '';
        this.setState({ uid: '' });
      }
    });
  }

  render() {
    return (
      <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/client" name="本日の利用状況" component={Client} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Auth uid={ this.state.uid }>
              <Switch>
                <Route path="/" name="Home" component={DefaultLayout} />
              </Switch>
            </Auth>
          </Switch>
      </HashRouter>
    );
  }
}

window.prefCode = [];
window.prefCode[1] = '北海道';
window.prefCode[2] = '青森県';
window.prefCode[3] = '岩手県';
window.prefCode[4] = '宮城県';
window.prefCode[5] = '秋田県';
window.prefCode[6] = '山形県';
window.prefCode[7] = '福島県';
window.prefCode[8] = '茨城県';
window.prefCode[9] = '栃木県';
window.prefCode[10] = '群馬県';
window.prefCode[11] = '埼玉県';
window.prefCode[12] = '千葉県';
window.prefCode[13] = '東京都';
window.prefCode[14] = '神奈川県';
window.prefCode[15] = '新潟県';
window.prefCode[16] = '富山県';
window.prefCode[17] = '石川県';
window.prefCode[18] = '福井県';
window.prefCode[19] = '山梨県';
window.prefCode[20] = '長野県';
window.prefCode[21] = '岐阜県';
window.prefCode[22] = '静岡県';
window.prefCode[23] = '愛知県';
window.prefCode[24] = '三重県';
window.prefCode[25] = '滋賀県';
window.prefCode[26] = '京都府';
window.prefCode[27] = '大阪府';
window.prefCode[28] = '兵庫県';
window.prefCode[29] = '奈良県';
window.prefCode[30] = '和歌山県';
window.prefCode[31] = '鳥取県';
window.prefCode[32] = '島根県';
window.prefCode[33] = '岡山県';
window.prefCode[34] = '広島県';
window.prefCode[35] = '山口県';
window.prefCode[36] = '徳島県';
window.prefCode[37] = '香川県';
window.prefCode[38] = '愛媛県';
window.prefCode[39] = '高知県';
window.prefCode[40] = '福岡県';
window.prefCode[41] = '佐賀県';
window.prefCode[42] = '長崎県';
window.prefCode[43] = '熊本県';
window.prefCode[44] = '大分県';
window.prefCode[45] = '宮崎県';
window.prefCode[46] = '鹿児島県';
window.prefCode[47] = '沖縄県';

window.prefOptions = [];
window.prefOptions[1] = { value: 1, label: '北海道' };
window.prefOptions[2] = { value: 2, label: '青森県' };
window.prefOptions[3] = { value: 3, label: '岩手県' };
window.prefOptions[4] = { value: 4, label: '宮城県' };
window.prefOptions[5] = { value: 5, label: '秋田県' };
window.prefOptions[6] = { value: 6, label: '山形県' };
window.prefOptions[7] = { value: 7, label: '福島県' };
window.prefOptions[8] = { value: 8, label: '茨城県' };
window.prefOptions[9] = { value: 9, label: '栃木県' };
window.prefOptions[10] = { value: 10, label: '群馬県' };
window.prefOptions[11] = { value: 11, label: '埼玉県' };
window.prefOptions[12] = { value: 12, label: '千葉県' };
window.prefOptions[13] = { value: 13, label: '東京都' };
window.prefOptions[14] = { value: 14, label: '神奈川県' };
window.prefOptions[15] = { value: 15, label: '新潟県' };
window.prefOptions[16] = { value: 16, label: '富山県' };
window.prefOptions[17] = { value: 17, label: '石川県' };
window.prefOptions[18] = { value: 18, label: '福井県' };
window.prefOptions[19] = { value: 19, label: '山梨県' };
window.prefOptions[20] = { value: 20, label: '長野県' };
window.prefOptions[21] = { value: 21, label: '岐阜県' };
window.prefOptions[22] = { value: 22, label: '静岡県' };
window.prefOptions[23] = { value: 23, label: '愛知県' };
window.prefOptions[24] = { value: 24, label: '三重県' };
window.prefOptions[25] = { value: 25, label: '滋賀県' };
window.prefOptions[26] = { value: 26, label: '京都府' };
window.prefOptions[27] = { value: 27, label: '大阪府' };
window.prefOptions[28] = { value: 28, label: '兵庫県' };
window.prefOptions[29] = { value: 29, label: '奈良県' };
window.prefOptions[30] = { value: 30, label: '和歌山県' };
window.prefOptions[31] = { value: 31, label: '鳥取県' };
window.prefOptions[32] = { value: 32, label: '島根県' };
window.prefOptions[33] = { value: 33, label: '岡山県' };
window.prefOptions[34] = { value: 34, label: '広島県' };
window.prefOptions[35] = { value: 35, label: '山口県' };
window.prefOptions[36] = { value: 36, label: '徳島県' };
window.prefOptions[37] = { value: 37, label: '香川県' };
window.prefOptions[38] = { value: 38, label: '愛媛県' };
window.prefOptions[39] = { value: 39, label: '高知県' };
window.prefOptions[40] = { value: 40, label: '福岡県' };
window.prefOptions[41] = { value: 41, label: '佐賀県' };
window.prefOptions[42] = { value: 42, label: '長崎県' };
window.prefOptions[43] = { value: 43, label: '熊本県' };
window.prefOptions[44] = { value: 44, label: '大分県' };
window.prefOptions[45] = { value: 45, label: '宮崎県' };
window.prefOptions[46] = { value: 46, label: '鹿児島県' };
window.prefOptions[47] = { value: 47, label: '沖縄県' };

export default App;
