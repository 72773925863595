import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';

class Auth extends Component {

  render() {
    console.log(this.props);

    if (this.props.uid === "-1") {
      // Now Loading ...
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <p className="text-center">Now Loading...</p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.props.uid === "") {
      // Login
      return <Redirect to={'/login'}/>;
    } else {
      return this.props.children;
    }
  }
}

export default Auth;
